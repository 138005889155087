import React, { useState } from 'react';

const teamMembers = [
  { name: 'Alice', info: 'Alice es desarrolladora front-end.', angle: 0 },
  { name: 'Bob', info: 'Bob es diseñador UX/UI.', angle: 45 },
  { name: 'Charlie', info: 'Charlie es ingeniero de datos.', angle: 90 },
  { name: 'Dave', info: 'Dave es administrador de sistemas.', angle: 135 },
  { name: 'Eve', info: 'Eve es especialista en seguridad.', angle: 180 },
  { name: 'Frank', info: 'Frank es desarrollador back-end.', angle: 225 },
  { name: 'Grace', info: 'Grace es gerente de proyectos.', angle: 270 },
  { name: 'Hank', info: 'Hank es analista de negocios.', angle: 315 },
];

const TeamWidget = () => {
  const [activeMember, setActiveMember] = useState(null);

  const handleMouseEnter = (name) => {
    setActiveMember(name);
  };

  const handleMouseLeave = () => {
    setActiveMember(null);
  };

  const handleClick = (name) => {
    setActiveMember(activeMember === name ? null : name);
  };

  return (
    <div className="team-container">
      {teamMembers.map((member, index) => {
        const angle = (member.angle * Math.PI) / 180;
        const x = 50 + 40 * Math.cos(angle);
        const y = 50 + 40 * Math.sin(angle);
        return (
          <div
            key={index}
            className={`team-member ${activeMember === member.name ? 'active' : ''}`}
            style={{
              left: `${x}%`,
              top: `${y}%`,
              transform: `translate(-50%, -50%) rotate(${member.angle}deg)`,
            }}
            onMouseEnter={() => handleMouseEnter(member.name)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleClick(member.name)}
          >
            <div style={{ transform: `rotate(-${member.angle}deg)` }}>{member.name}</div>
            {activeMember === member.name && (
              <div
                className="team-info"
                style={{
                  transform: `rotate(${member.angle}deg) translateY(-80px) rotate(-${member.angle}deg)`,
                }}
              >
                <p>{member.info}</p>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default TeamWidget;