import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/aampolleta.gif'; // Importa el logo
import '../App.css'; // Importa el archivo CSS

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <header>
        <div className="logo-container">
            <img className="logo-img" src={logo} alt="Thovex Factory Logo" />
            <div className="logo-text">THOVEX</div>    
            <div className="logo-text small">SOFTWARE FACTORY</div>  
        </div>
      <nav className={`nav-menu ${isOpen ? 'open' : ''}`}>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/nosotros">Nosotros</Link></li>
          <li><Link to="/servicios">Servicios</Link></li>
          <li><Link to="/login">Login</Link></li>
        </ul>
      </nav>
      <div className="menu-icon" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </header>
  );
};

export default Header;