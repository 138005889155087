import React from 'react';
import PropTypes from 'prop-types';
import '../App.css'; // Asegúrate de tener los estilos para las tabs

const Tabs = ({ tabs, currentTab, onTabClick }) => {
  return (
    <div className="tabs-container">
      {tabs && tabs.map((tab, index) => (
        <button
          key={index}
          className={`tab-button ${currentTab === index ? 'active' : ''}`}
          onClick={() => onTabClick(index)}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentTab: PropTypes.number.isRequired,
  onTabClick: PropTypes.func.isRequired,
};

export default Tabs;