import React from 'react';
import './App.css'; // Importa los estilos globales
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import Home from './pages/Home';
import Nosotros from './pages/Nosotros';
import Servicios from './pages/Servicios';
import Login from './pages/Login';
import Header from './components/Header';
import AppsHeader from './components/AppsHeader';
import EMFLayout from './clients/emf/EMFLayout';
import Tabs from './components/Tabs';

const AppRoutes = () => {
  const routes = useRoutes([
    { path: "/", element: <PageWithHeader Component={Home} /> },
    { path: "/nosotros", element: <PageWithHeader Component={Nosotros} /> },
    { path: "/servicios", element: <PageWithHeader Component={Servicios} /> },
    { path: "/login", element: <PageWithHeader Component={Login} /> },
    { path: "/emf/*", element: <PageWithAppsHeader Component={EMFLayout} title="EMF | APPS" /> },
    { path: "*", element: <PageWithAppsHeader Component={() => <div>404 Not Found</div>} /> }
  ]);

  return routes;
};

const PageWithHeader = ({ Component }) => (
  <div className="homepage-background">
    <Header />
    <Component />
  </div>
);

const PageWithAppsHeader = ({ Component, title, currentTab, tabs, onTabClick }) => (
  <div className= "apps-main">
    <AppsHeader title={title} />
    <Tabs tabs={tabs} currentTab={currentTab} onTabClick={onTabClick} />
    <Component />
  </div>
);
const App = () => {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

export default App;