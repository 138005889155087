import React from 'react';
import { useNavigate } from 'react-router-dom';
// import logo from '../assets/logo.gif'; // Importa el logo
import '../App.css'; // Importa el archivo CSS


const AppsHeader = ({title}) => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  }; 
  return (

    <header className ="apps-header">
      <div className="apps-client-title">{title}</div>
      <div className="apps-logo-container" onClick={handleLogoClick}>
        {/* <img className="apps-logo-img" src={logo} alt="Thovex Factory Logo" /> */}
        <div className="apps-logo-text">THOVEX</div>
        <div className="apps-logo-text small">SOFTWARE</div>  
      </div>
    </header>
    
  );
};

export default AppsHeader;