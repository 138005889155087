import React from 'react';
import TeamWidget from '../components/TeamWidget';

const Nosotros = () => {
  return (
    <div className="aboutus">
    <div className="container">
      <h1>Thovex Software Factory</h1>
      <p>Nace en 2024 con el objetivo de...</p>
      <TeamWidget />
    </div>
    </div>
  );
};

export default Nosotros;