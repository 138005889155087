import {React,useState} from 'react';
import { useRoutes, useNavigate} from 'react-router-dom';
import Tabs from '../../components/Tabs';
import AYR from './aportesyrescates/AYR';
import TransformarCSV from './transformarCSV/TransformarCSV';


const EMFRoutes = () => {
    const routes = useRoutes([
      { path: "ayr", element: <AYR /> },
      { path: "csv", element: <TransformarCSV /> },
      { path: "*", element: <div>404 Not Found</div> }
    ]);
  
    return routes;
  };
  
  const EMFLayout = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const navigate = useNavigate();
    const tabs = ['Aportes y Rescates', 'Transformar CSV', 'Cuadre Balance Diario'];
  
    const handleTabClick = (index) => {
      setCurrentTab(index);
      if (index === 0) navigate('/emf/ayr');
      if (index === 1) navigate('/emf/csv');
    };
  
    return (
      <div className="default-background">
        <Tabs tabs={tabs} currentTab={currentTab} onTabClick={handleTabClick} />
        <EMFRoutes />
      </div>
);
}

export default EMFLayout;