import React, { useEffect, useState } from 'react';
import './AYR.css';
import { format, parseISO, getDay, subDays, isWeekend,addHours } from 'date-fns';
import { es } from 'date-fns/locale';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { AportesRescatesNetoChart, AcumuladosChart } from './Charts';

const apiUrl = 'https://thovex-web-production.up.railway.app';

// const apiUrl = 'http://localhost:3000';

const AYR= () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: 'fecha', direction: 'asc' });
    const [dateToUpdate, setDateToUpdate] = useState(subDays(new Date(), 2));
    const [currentPage, setCurrentPage] = useState(0);
    const [showAll, setShowAll] = useState(false);
    const [darkMode, setdarkMode] = useState(true);
    
    const fetchData = async () => {
        try {
            const response = await fetch(`${apiUrl}/api/fetch-data`);
            const result = await response.json();
            if (Array.isArray(result)) {
                setData(result);
            } else {
                console.error('Fetched data is not an array:', result);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const updateData = async () => {
        setLoading(true);
        try {
            await fetch(`${apiUrl}/api/updateall`);
            await fetchData();
            alert('Data updated successfully.');
        } catch (error) {
            console.error('Error updating data:', error);
            alert('Error updating data.');
        } finally {
            setLoading(false);
        }
    };

    const updateSpecificDate = async (date) => {
        const today = new Date();
        if (date >= today) {
            alert('The selected date must be in the past.');
            return;
        }
        setLoading(true);
        try {
            const formattedDate = format(date, 'yyyy-MM-dd');
            await fetch(`${apiUrl}/api/update/${formattedDate}`);
            await fetchData();
            alert(`Data for ${formattedDate} updated successfully.`);
        } catch (error) {
            console.error(`Error updating data for ${date}:`, error);
            alert(`Error updating data for ${date}.`);
        } finally {
            setLoading(false);
        }
    };

    const downloadExcel = async () => {
        try {
            const response = await fetch(`${apiUrl}/api/download-excel`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
            });
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Aportes_y_Rescates.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (error) {
            console.error('Error downloading Excel:', error);
        }
    };

    useEffect(() => {
        fetchData();
        const intervalId = setInterval(fetchData, 24 * 60 * 60 * 1000); // 24 horas
        return () => clearInterval(intervalId);
    }, []);

    const formatNumber = (value) => {
        const numberValue = typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value;
        if (!isNaN(numberValue)) {
            return numberValue.toLocaleString('de-DE', {});
        }
        return value;
    };

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const sortedData = React.useMemo(() => {
        let sortableItems = [...data];
        sortableItems.sort((a, b) => {
            if (!a[sortConfig.key] || !b[sortConfig.key]) return 0;
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
        return sortableItems;
    }, [data, sortConfig]);

    const groupedData = React.useMemo(() => {
        const groups = {};
        sortedData.forEach(item => {
            if (item && item.fecha) {
                const date = addHours(parseISO(item.fecha), 12);
                const monthYear = format(date, 'yyyy-MM');
                if (!groups[monthYear]) {
                    groups[monthYear] = [];
                }
                groups[monthYear].push(item);
            }
        });
        return Object.keys(groups).map(monthYear => groups[monthYear]);
    }, [sortedData]);

    const currentRows = showAll ? sortedData : (groupedData[currentPage] || []);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        setShowAll(false);
    };

    const handleShowAll = () => {
        setShowAll(true);
    };

    const getButtonLabel = (group) => {
        if (group.length > 0) {
          const date = addHours(parseISO(group[0].fecha), 12);
           return format(date, 'MMM', { locale: es }).toUpperCase(); 
        }
        return 'N/A';
    };

    const getDayInitial = (date) => {
        const days = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];
        return days[getDay(date)];
    };
    
    const toggledarkMode = () => {
      setdarkMode(!darkMode);
      document.body.classList.toggle('dark-mode', darkMode);
  };

    return (
      <div className={`app-container ${darkMode ? '' : 'dark-mode'}`}>
        <div className="update-buttons">
            <button
              onClick={updateData}
              disabled={loading}
              className="update-button">
              {loading ? "Actualizando..." : "Actualizar todo"}
            </button>
            <button
              onClick={() => updateSpecificDate(dateToUpdate)}
              disabled={loading || !dateToUpdate}
              className="update-button">
              {loading ? "Actualizando..." : `Actualizar un dia`}
            </button>
            <DatePicker
              selected={dateToUpdate}
              onChange={(date) => {
                if (date < subDays(new Date(), 1) && !isWeekend(date)) {
                  setDateToUpdate(date);
                } else {
                  alert("Por favor elegir fecha anterior a hoy y que no sea fin de semana.");
                }
              }}
              dateFormat="dd-MM-YYYY"
              className="datepicker"
            />
            <div className="switch-container">
            <label className="switch">
                <input type="checkbox" checked={!darkMode} onChange={toggledarkMode} />
                <span className="slider"></span>
            </label>
            <span className="mode-label">Modo Oscuro</span>
          </div>
        </div>
        <div className="pagination-container">
          <div className="pagination">
            <button onClick={handleShowAll} className={showAll ? "active" : ""}>
              ALL
            </button>
            {groupedData.map((group, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index)}
                className={currentPage === index && !showAll ? "active" : ""}>
                {getButtonLabel(group)}
              </button>
            ))}
          </div>
          <button onClick={downloadExcel} className="download-button">
              <FontAwesomeIcon icon={faFileExcel} size="2x" />
          </button>
        </div>
        <div className="main-content">
          <div className="table-container">
            <table className="data-table">
              <thead>
                <tr>
                  <th>Dia</th>
                  <th onClick={() => requestSort("fecha")}>Fecha</th>
                  <th onClick={() => requestSort("flujo_aportes")}>
                    Flujo Aportes
                  </th>
                  <th onClick={() => requestSort("flujo_rescates")}>
                    Flujo Rescates
                  </th>
                  <th onClick={() => requestSort("neto_aportes_rescates")}>
                    Neto Aportes-Rescates
                  </th>
                  <th onClick={() => requestSort("acumulado_aportes")}>
                    Acumulado Aportes
                  </th>
                  <th onClick={() => requestSort("acumulado_rescates")}>
                    Acumulado Rescates
                  </th>
                  <th onClick={() => requestSort("neto_acumulado")}>
                    Neto Acumulado
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentRows.length > 0 ? (
                  currentRows.map((row) => (
                    <tr key={row.id}>
                      <td>{getDayInitial(addHours(parseISO(row.fecha), 12))}</td>
                      <td>{format(addHours(parseISO(row.fecha), 12), "dd-MM-yyyy")}</td>
                      <td>{formatNumber(row.flujo_aportes)}</td>
                      <td>{formatNumber(row.flujo_rescates)}</td>
                      <td>{formatNumber(row.neto_aportes_rescates)}</td>
                      <td>{formatNumber(row.acumulado_aportes)}</td>
                      <td>{formatNumber(row.acumulado_rescates)}</td>
                      <td>{formatNumber(row.neto_acumulado)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">No hay data disponible</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="filters-info">
            <p>Información obtenida de Asociación de FFMM: https://estadisticas2.aafm.cl/DailyStadistics</p>
            <p>Los filtros ocupados para obtener la tabla son:</p>
            <p>Categorías AFM:</p>
            <ul>
              <li>Accionario Nacional</li>
              <li>Accionario Nacional Large Cap</li>
              <li>Accionario Nacional Otros</li>
              <li>Accionario Nacional Small & Mid Cap</li>
              <li>Inversionistas Calificados Accionario Nacional</li>
            </ul>
            <p>Administradora: Todos</p>
            <p>APV: Todos</p>
            <p>Tipo de Inversión: Nacional</p>
            <p>Checkboxes marcados: Flujo Aporte y Flujo Rescate.</p>
          </div>
        </div>
        <div className="charts-container">
          <AportesRescatesNetoChart data={currentRows} darkMode={darkMode}  />
          <div style={{ height: '50px' }}></div>
          <AcumuladosChart data={currentRows} darkMode={darkMode}  />
        </div>
      </div>
    );       
};

export default AYR;