import React from "react";
import wslogo from '../assets/whatsapplogo-gold.svg'; 
import wslogomobile from '../assets/whatsapplogo-goldbg.png';

const Home = () => {
  return (
    <div>
      <div className="content">
        <div className="left-half">
          <h1>CREANDO SOLUCIONES TECNOLÓGICAS</h1>
        </div>
        <div className="right-half">
          <h1>
            A MEDIDA<span className="blinking-cursor">I</span>
          </h1>
        </div>
      </div>
      <div className="footer-container">
        <a href="https://wa.me/56996269600" rel="noreferrer" target="_blank">
          <img className="logo-whatsapp" src={wslogo} alt="Logo Whatsapp" />
          <img className="logo-whatsapp-mobile" src={wslogomobile} alt="Logo Whatsapp" />
        </a>
        <p className="copyright">© Photography by <a href="https://www.linkedin.com/in/umuty1/">Umut Yurdakök</a>. Lago Caburgua, Chile</p>
      </div>
      </div>
  );
};

export default Home;