import React from 'react';

const Servicios = () => {
  return (
    <div className='servicios'>
      <h1>Servicios</h1>
      <p>Descripción de los servicios que ofrecemos</p>
    </div>
  );
};

export default Servicios;